<template>
  <template v-if="!loading">
    <header-line></header-line>
    <div class="content content--center">
      <section class="redeem">
        <div class="redeem__title">
          <strong>{{ t('label.amount_left') }}</strong>
          {{ numberFormat(voucher.amountLeft, data.currencySymbol, true) }}
        </div>
        <div class="redeem__form">
          <form action="#" class="form" v-on:submit.prevent="redeem">
            <div class="form__separator"></div>
            <div class="form__row" v-bind:class="{'error': isFieldInvalid('pin')}">
              <label class="form__label" for="vpin">{{ t('label.voucher_pin') }}</label>
              <input class="form__input" type="text" name="vpin" id="vpin"
                     v-model="redemptionParams.pin"
                     v-on:keyup="clearErrors('pin')"
                     autocomplete="off"
              >
              <div class="error" v-if="isFieldInvalid('pin')">
                {{ t(getFieldError('pin')) }}
              </div>
            </div>
            <div class="form__row" v-bind:class="{'error': isFieldInvalid('amount')}">
              <label class="form__label" for="vamount">{{ t('label.amount') }} ({{ data.currency }})</label>
              <input class="form__input" type="text" name="vamount" id="vamount"
                     v-model="redemptionParams.amount"
                     v-on:keyup="clearErrors('amount')"
                     autocomplete="off"
              >
              <div class="error" v-if="isFieldInvalid('amount')">
                {{ t(getFieldError('amount')) }}
              </div>
            </div>
            <div class="form__row" v-bind:class="{'error': isFieldInvalid('comment')}">
              <label class="form__label" for="vcomment">{{ t('label.comment_optional') }}</label>
              <input class="form__input" type="text" name="vcomment" id="vcomment"
                     v-model="redemptionParams.comment"
                     v-on:keyup="clearErrors('comment')"
                     autocomplete="off"
              >
              <div class="error" v-if="isFieldInvalid('comment')">
                {{ t(getFieldError('comment')) }}
              </div>
            </div>
            <div class="form__row--error" v-if="errorResponse">{{ errorResponse }}</div>
            <div class="form__row form__row--btns">
              <input class="btns btn-orange" type="submit" name="submit" v-bind:value="t('button.confirm_redeem')">
            </div>
          </form>
        </div>
      </section>
    </div>
    <footer-small-line></footer-small-line>
  </template>
  <page-loader v-else></page-loader>
</template>


<script>
import {useI18n} from 'vue-i18n'
import {useStore} from 'vuex'
import {watchEffect, ref, computed, reactive, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import HeaderLine from "@/components/HeaderLine";
import useAdmin from "@/helpers/useAdmin";
import useAdminVoucher from "@/helpers/useAdminVoucher";
import {numberFormat} from "@/helpers/PriceHelper";
import PageLoader from "@/components/PageLoader";
import FooterSmallLine from "@/components/FooterSmallLine";
import {isFieldInvalid, isFormInvalid, clear, getFieldError, redemptionParamsValidate} from "@/helpers/ValidatorHelper";
import RequestHandler from '@apiClient/RequestHandler';
import {ACTION_TYPES} from "@/constants";

export default {
  name: 'admin-redeem',
  components: {FooterSmallLine, PageLoader, HeaderLine},
  setup() {
    const loading = ref(true);
    const errorResponse = ref(null);
    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const code = route.params.code;

    const {voucher, loadVoucher} = useAdminVoucher();
    loadVoucher(route, router);

    const {admin, redirectIfNotLoggedIn} = useAdmin();

    const data = computed(() => store.getters.data);

    watchEffect(() => {
      redirectIfNotLoggedIn(router);
      loading.value = !data.value || !voucher.value || !admin.value;
    })
    onMounted(() => clear());

    const redemptionParams = reactive({
      pin: null,
      amount: null,
      comment: null,
    });

    const redeem = async () => {
      errorResponse.value = null;
      redemptionParamsValidate(redemptionParams, voucher.value.amountLeft);
      if (!isFormInvalid()) {
        loading.value = true;
        try {
          await RequestHandler.redeemVoucher({...redemptionParams, code: voucher.value.voucherCode});
          store.dispatch(ACTION_TYPES.cleanBookedVoucher);
          router.push({name: 'admin_voucher', params: {...route.params, code: code}});
        } catch (error) {
          errorResponse.value = error.response.data.error;
          loading.value = false;
        }
      }
    }

    const i18n = useI18n();

    return {
      t: i18n.t,
      voucher,
      data,
      loading,
      redemptionParams,
      redeem,
      errorResponse,
      getFieldError,
      isFieldInvalid,
      numberFormat,
      clearErrors: clear
    };
  }
}
</script>
